import { useStore } from "../store";

export const playOneAnimationWithoutRotation = (
  animationSequence?: number[],
  isFirst?: boolean,
  isLast?: boolean
) => {
  return new Promise((resolve, reject) => {
    const { apiref, setAnimationStopped } = useStore.getState();
    const firstSeqTime = animationSequence?.[0];
    const secondSeqTime = animationSequence?.[1];

    const animationDuration =
      firstSeqTime && secondSeqTime && secondSeqTime - firstSeqTime;

    isFirst && setAnimationStopped(false);

    apiref.pause(() => {
      apiref.seekTo(firstSeqTime, () => {
        apiref.play();
        useStore.setState({ animationIsPlaying: true });

        setTimeout(() => {
          apiref.pause(() => {
            isLast && useStore.setState({ animationIsPlaying: false });
            resolve(true);
          });
        }, animationDuration && animationDuration * 1000);
      });
    });
  });
};

export const playCustomAnimationWithoutRotation = () => {
  const { activeSubLink } = useStore.getState();

  if (
    activeSubLink.animationSequences &&
    activeSubLink.animationSequences.length
  ) {
    for (
      let i = 0, p = Promise.resolve();
      i < activeSubLink.animationSequences.length;
      i++
    ) {
      const isLast = activeSubLink.animationSequences?.length === i + 1;
      const isFirst = i === 0;

      p = p
        .then(() =>
          playOneAnimationWithoutRotation(
            [
              //@ts-ignore
              activeSubLink.animationSequences[i][0],
              //@ts-ignore
              activeSubLink.animationSequences[i][1],
            ],
            isFirst,
            isLast
          )
        )
        .then(() => {
          // console.log(i + 1, "Animation played")
        });
    }
  }
};
