import React from "react";
import {
  Title,
  Text,
  Spacer,
  Image,
  CustomLink,
  TextInline,
} from "../dynamic.components";
import { useStore } from "src/store";
import validation_regulatory_support from "src/assets/images/overview/B_02_validation.png";

export const ValidationRegulatorySupport: React.FC = () => {
  const { title } = useStore().activeSubLink;

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="Validation guides and extractables information are available for ÄKTA ready™ flow kits and ReadyToProcess™ prepacked columns." />
        <Spacer />
        <Text text="It is beneficial to subscribe to the Change Control Notification (CCN) service which will provide you with information on changes about the products." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: validation_regulatory_support,
              alt: "validation regulatory support",
              customWidth: "100%",
            },
          ]}
        />
        <Spacer x={2} />
        <TextInline text="Register at " />
        <CustomLink
          href="https://www.cytivalifesciences.com/support/quality/regulatory-support?extcmp=cy23007-global-bp-pi-aktaready-3d-ot-qrc"
          text="cytiva.com/rsf"
          inline={true}
        />
        <Spacer />
        <Text text="Installation qualification and operational qualification (IQ/OQ) are also available." />
        <CustomLink
          href="https://www.cytivalifesciences.com/support/optirun-service-solutions/instrument-qualification-iq-oq?extcmp=cy23007-global-bp-pi-aktaready-3d-ot-qrc"
          text="Learn more"
        />
      </div>
    </>
  );
};
