import React, { useEffect } from "react";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import { useStore } from "src/store";
import change_over from "src/assets/images/overview/A_01_time.png";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { playCustomAnimation } from "src/services/animations";

export const ChangeOverProcess: React.FC = () => {
  const {
    activeSubLink: { title },
    setAnimationStopped,
    sidebarStatus,
    animationStopped,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("1-3")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("1-3")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="The flow kit is installed in less than 15 min. and can be easily removed from the system post-use. This enables fast change over and efficient production while minimizing risk of cross-contamination." />
        <Spacer />
        <Text text="Set-up time can be significantly reduced when ÄKTA ready™ extended system and ReadyToProcess™ prepacked columns are used together." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: change_over,
              alt: "Changeover process",
              customWidth: "80%",
            },
          ]}
        />
      </div>
    </>
  );
};
