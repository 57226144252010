import React from "react";
import { Title, Text, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";

export const XcellerexXduoMixers: React.FC = () => {
  const { title } = useStore().activeSubLink;
  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="ÄKTA ready™ extended is configured to seamlessly communicate with up to four Xcellerex™ XDUO single-use mixers. This enables control and monitoring of mixers from UNICORN™ control software:" />
        <Spacer />
        <List
          x={0.5}
          listArray={[
            "Splitting the load material into equally large chromatography cycles.",
            "Ensuring that the bag or biocontainer does not run out of liquid or is not overfilled with liquid",
            "Ability to adjust conductivity or pH of product before chromatography run for suitable loading conditions",
            "Ability to adjust conductivity or pH of product after chromatography run for low pH viral inactivation or change conditions before processing at subsequent step.",
            "Generate a report in UNICORN software that includes the operational details performed on the mixer.",
          ]}
        />
      </div>
    </>
  );
};
