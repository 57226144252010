import { CustomLink } from "../types";

export enum SubLinkId {
  footprint = "1-1",
  system_status = "1-2",
  changeover_process = "1-3",
  validation_regulatory_support = "1-4",

  scalabilty = "2-1",
  input_output_connectivity = "2-2",
  xcellerex_xduo_mixers = "2-3",

  dry_wet_test_flow_kits = "3-1",
  column_range = "3-2",
  column_setup = "3-3",

  integrated_hmi_unicorn = "4-1",
  dcs_options = "4-2",
  opc_options = "4-3",

  pump_inlets = "5-1",
  sensors = "5-2",
  inline_air_sensor_trap = "5-3",
  column_outlets = "5-4",
}

export const links: CustomLink[] = [
  {
    id: "1",
    text: "Overview",
    title: "Overview",
    hasSublinks: true,
    subLinks: [
      {
        id: "1-1",
        text: "Footprint",
        title: "Footprint",
        annotations: ["A1"],
        staticFrameWithHotspot: [
          {
            annotation: "A1",
            frame: 60,
          },
        ],
      },
      {
        id: "1-2",
        text: "System status",
        title: "System status",
        annotations: ["A2"],
        extras: {
          animationSequences: [[73.6, 79.6]],
          staticFrameWithHotspot: [
            { name: "off", frame: 1, annotation: "" },
            { name: "white", frame: 50.7, annotation: "" },
            { name: "green", frame: 47.7, annotation: "" },
            { name: "yellow", frame: 47, annotation: "" },
            { name: "red", frame: 46.3, annotation: "" },
          ],
        },
      },
      {
        id: "1-3",
        text: "Changeover process",
        title: "Changeover process",
        annotations: ["A3"],
        animations: ["Scene"],
        animationSequences: [[62.33, 66]],
      },
      {
        id: "1-4",
        text: "Validation & regulatory",
        title: "Validation and regulatory support",
        annotations: ["A4"],
      },
    ],
  },
  {
    id: "2",
    text: "Flexibility",
    title: "Flexibility",
    hasSublinks: true,
    subLinks: [
      {
        id: "2-1",
        text: "Scalability",
        title: "Scalability",
        annotations: ["B1"],
      },
      {
        id: "2-2",
        text: "Input/output",
        title: "Input/output connectivity",
        annotations: ["B2-1"],
        extras: {
          staticFrameWithHotspot: [
            {
              annotation: "B2-1",
              frame: 0,
              name: "air_sensor_connections",
            },
            {
              annotation: "B2-2",
              frame: 0,
              name: "other_connections",
            },
            {
              annotation: "B2-3",
              frame: 44.16,
              name: "profibus_connection",
            },
          ],
        },
      },
      {
        id: "2-3",
        text: "Xcellerex XDUO mixers",
        title: "Xcellerex XDUO mixers",
        annotations: ["B3"],
        staticFrameWithHotspot: [
          {
            annotation: "B3",
            frame: 44.16,
          },
        ],
      },
    ],
  },
  {
    id: "3",
    text: "System operations",
    title: "System operations",
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "3-1",
        text: "Flow kit dry/wet test",
        title: "Flow kit dry/wet test",
        annotations: ["C1-1"],
        animations: ["Scene"],
        animationSequences: [[62.33, 66]],
        staticFrameWithHotspot: [
          {
            annotation: "C1-2",
            frame: 61,
            name: "kit_only",
          },
          {
            annotation: "C1-3",
            frame: 40,
            name: "kit_column",
          },
        ],
      },
      {
        id: "3-2",
        text: "Column range",
        title: "Attach prepacked column",
        annotations: ["C2"],
        animations: ["Scene"],
        animationSequences: [[30.03, 33.33]],
      },
      {
        id: "3-3",
        text: "Column set-up",
        title: "Removing air from column",
        annotations: ["C3"],
        animations: ["Scene"],
        animationSequences: [[80.66, 84]],
      },
    ],
  },
  {
    id: "4",
    text: "Automation",
    title: "Automation",
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "4-1",
        text: "HMI with UNICORN™",
        title: "Integrated HMI with UNICORN™ control software",
        annotations: ["D1"],
        staticFrameWithHotspot: [
          {
            annotation: "D1",
            frame: 58,
          },
        ],
      },
      {
        id: "4-2",
        text: "DCS options",
        title: "Distributed control system options",
        annotations: ["D2"],
        staticFrameWithHotspot: [
          {
            annotation: "D2",
            frame: 58.66,
          },
        ],
      },
      {
        id: "4-3",
        text: "OPC options",
        title: "OPC options",
        annotations: ["D3"],
        staticFrameWithHotspot: [
          {
            annotation: "D3",
            frame: 59.33,
          },
        ],
      },
    ],
  },
  {
    id: "5",
    text: "Process control",
    title: "Process control",
    animationSequences: [],
    hasSublinks: true,
    subLinks: [
      {
        id: "5-1",
        text: "Pump & inlets",
        title: "Pump and inlets",
        annotations: ["E1-1"],
        animations: ["Scene"],
        animationSequences: [[0.83, 7.5]],
        extras: {
          staticFrameWithHotspot: [
            {
              annotation: "E1-2",
              frame: 52,
            },
          ],
        },
      },
      {
        id: "5-2",
        text: "Sensors",
        title: "Sensors",
        annotations: ["E2-1"],
        staticFrameWithHotspot: [
          {
            annotation: "E2-1",
            frame: 52.66,
            name: "conductivity",
          },
          {
            annotation: "E2-2",
            frame: 53.33,
            name: "pressure",
          },
          {
            annotation: "E2-3",
            frame: 56,
            name: "flow",
          },
          {
            annotation: "E2-4",
            frame: 56.66,
            name: "uv",
          },
          {
            annotation: "E2-5",
            frame: 55.33,
            name: "pH_temperature",
          },
        ],
      },
      {
        id: "5-3",
        text: "Air sensor & trap",
        title: "Air sensors and air trap",
        annotations: ["E3-1"],
        staticFrameWithHotspot: [
          {
            annotation: "E3-1",
            frame: 54,
            name: "air_trap",
          },
          {
            annotation: "E3-2",
            frame: 54.66,
            name: "air_sensor",
          },
          {
            annotation: "E3-3",
            frame: 10.5,
            name: "clamp_on",
          },
        ],
      },
      {
        id: "5-4",
        text: "Column & outlets",
        title: "Column valve and outlet manifold",
        annotations: ["E4-1"],
        animations: ["Scene"],
        animationSequences: [[30.03, 33.33]],
        extras: {
          animationSequences: [[68.33, 71.66]],
          annotations: ["E4-2"],
          activeAnimation: 0,
        },
      },
    ],
  },
];
