import React from "react";
import { playHotspot } from "src/services/annotations";
import { useStore } from "src/store";
import { CustomLink } from "src/types";

type NavLinkProps = {
  link: CustomLink;
};

const NavLink: React.FC<NavLinkProps> = ({ link }) => {
  const {
    activeNavLink,
    setActiveNavLink,
    setDropdown,
    hasDropdown,
    dropdown,
  } = useStore();
  return (
    <li
      className={`c-cytiva__nav-list${
        hasDropdown && dropdown ? "-dp" : ""
      }__item`}
    >
      <button
        className={`${
          activeNavLink.id === link.id ? "button--hover" : ""
        } button button--secondary button--large button--inline`}
        onClick={() => {
          setDropdown();
          setActiveNavLink(link);
          link.annotations && playHotspot(link.annotations[0]);
        }}
      >
        {link.title}
      </button>
    </li>
  );
};

export default NavLink;
