import React, { useEffect, useState } from "react";
import { playCustomAnimation, playOneAnimation } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";
import play from "src/assets/svg/play.svg";

export const ColumnOutlets: React.FC = (): JSX.Element => {
  const [state, setState] = useState(0);
  const {
    sidebarStatus,
    animationStopped,
    animationIsPlaying,
    setAnimationStopped,
    setActiveAnimation,
    activeSubLink: { title, extras, annotations, animationSequences },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("5-4")) {
      setState(0);
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("5-4")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  const handleSelectHotspot = (animationName: string) => {
    let annotation = "";
    let animationSeq = [0, 0];

    if (animationName === "upflow") {
      annotation = (annotations && annotations[0]) || "";
      animationSeq = (animationSequences && animationSequences[0]) || [];
      setActiveAnimation(0);
    }

    if (animationName === "downflow") {
      annotation = (extras?.annotations && extras?.annotations[0]) || "";
      animationSeq =
        (extras?.animationSequences && extras?.animationSequences[0]) || [];
      setActiveAnimation(1);
    }

    playOneAnimation(annotation, animationSeq, true, true);
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="The column valve allows for flow through the column on both directions and includes a by-pass option." />
        <Spacer />
        <Text text="The processed liquid can be collected in up to six different collection lines using the six outlet valves. This provides flexibility for waste and pool collection possibilities." />
        <Spacer x={2} />
        <button
          onClick={() => handleSelectHotspot("upflow")}
          className={`button button--secondary button--large button--fixwidth ${
            animationIsPlaying
              ? "button--disabled"
              : extras?.activeAnimation === 0
              ? "button--hover"
              : ""
          }`}
        >
          <span style={{ margin: "auto" }}>Upflow</span>
          <img
            className={`play-icon ${
              animationIsPlaying && extras?.activeAnimation === 0
                ? "play-icon-animate"
                : ""
            }`}
            src={play}
            alt="play animation"
          />
        </button>
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("downflow")}
          className={`button button--secondary button--large button--fixwidth ${
            animationIsPlaying
              ? "button--disabled"
              : extras?.activeAnimation === 1
              ? "button--hover"
              : ""
          }`}
        >
          <span style={{ margin: "auto" }}>Downflow</span>
          <img
            className={`play-icon ${
              animationIsPlaying && extras?.activeAnimation === 1
                ? "play-icon-animate"
                : ""
            }`}
            src={play}
            alt="play animation"
          />
        </button>
      </div>
    </>
  );
};
