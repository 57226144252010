import React, { useEffect } from "react";
import { playCustomAnimation } from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import {
  Title,
  Text,
  Spacer,
  Image,
  TextInline,
  CustomLink,
} from "../dynamic.components";
import column_range from "src/assets/images/system operations/C_01_column-range_hilite.png";

export const ColumnRange: React.FC = () => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { title },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-2")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("3-2")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="ÄKTA ready™ extended is designed to work seamlessly with ReadyToProcess™ prepacked columns. They are available in seven inner diameters from 8 cm to 60 cm and three standard bed heights (15, 20 or 25 cm). Custom bed heights also available." />
        <Spacer />
        <Image
          images={[
            {
              src: column_range,
              alt: "ReadyToProcess™ prepacked columns",
              customWidth: "100%",
            },
          ]}
        />
        <Spacer />
        <Text text="The prepacked columns are available for most Cytiva resins including MabSelect™, Capto™ and Sepharose™ resins." />
        <Spacer />
        <CustomLink
          inline={true}
          text="Learn more"
          href="https://www.cytivalifesciences.com/solutions/bioprocessing/products-and-solutions/downstream-bioprocessing/single-use-for-downstream/readytoprocess-columns?extcmp=cy23007-global-bp-pi-aktaready-3d-ot-qrc"
        />
      </div>
    </>
  );
};
