export const MODEL_UID = process.env.REACT_APP_MODEL_UID as string;
export const deviceName = "Äkta ready™ extended";
export const cameraMoveTiming = 3.2;
export const resetCameraAnnotation = "DEFAULT_POS";
export const defaultFrame = 0;
export const multiHotspotComponentId = [];
export const viewOption = {
  annotation: 0,
  // Usage: Setting to [1 – 100] will automatically load that annotation when the viewer starts.
  annotation_tooltip_visible: 0,
  // Usage: Setting to 0 will hide annotations tooltip by default.
  annotations_visible: 0,
  // Usage: Setting to 0 will hide annotations by default.
  autospin: 0,
  // Usage: Setting to any other number will cause the model to automatically spin around the z-axis after loading.
  autostart: 1,
  // Usage: Setting to 1 will make the model load immediately once the page is ready, rather than waiting for a user to click the Play button.
  cardboard: 0,
  // Usage: Start the viewer in stereoscopic VR Mode built for Google Cardboard and similar devices.
  camera: 0,
  // Usage: Setting to 0 will skip the initial animation that occurs when a model is loaded, and immediately show the model in its default position.
  preload: 0,
  // Usage: Setting to 1 will force all resources (textures) to download before the scene is displayed.
  ui_stop: 0,
  // Usage: Setting to 1 will make the model's background transparent
  ui_animations: 0,
  // Usage: Setting to 0 will hide the animation menu and timeline.
  ui_annotations: 0,
  // Usage: Setting to 0 will hide the Annotation menu.
  ui_ar: 0,
  // Usage: Setting to 0 will hide the View in AR button.
  ui_controls: 1,
  // Usage: Setting to 0 will hide all the viewer controls at the bottom of the viewer (Help, Settings, Inspector, VR, Fullscreen, Annotations, and Animations).
  ui_fullscreen: 0,
  // Usage: Setting to 0 will hide the Fullscreen button.
  ui_general_controls: 1,
  // Usage: Setting to 0 will hide main control buttons in the bottom right of the viewer (Help, Settings, Inspector, VR, Fullscreen).
  ui_help: 0,
  // Usage: Setting to 0 will hide the Help button.
  ui_hint: 1,
  // Usage: Setting to 0 will always hide the viewer hint animation ("click & hold to rotate"). Setting to 1 will show the hint the first time per browser session (using a cookie). Setting to 2 will always show the hint.
  ui_infos: 0,
  // Usage: Setting to 0 will hide the model info bar at the top of the viewer.
  ui_inspector: 0,
  // Usage: Setting to 0 will hide the inspector button.
  ui_settings: 0,
  // Usage: Setting to 0 will hide the Settings button.
  ui_vr: 0,
  // Usage: Setting to 0 will hide the View in VR button.
  ui_watermark_link: 0,
  // Usage: Setting to 0 remove the link from the Sketchfab logo watermark.
  ui_color: "00876F",
  // Usage: Setting to a hexidecimal color code (without the #) or a HTML color name will change the color of the viewer loading bar.
  ui_watermark: 0, // Usage: Setting to 0 remove the Sketchfab logo watermark.
  ui_start: 0,
};
