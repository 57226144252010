import React from "react";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import { useStore } from "src/store";
import DCS_screen from "src/assets/images/automation/D_02_screenshot.png";

export const DcsOptions: React.FC = () => {
  const {
    activeSubLink: { title },
  } = useStore();

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="When other control systems are used with the ÄKTA ready™ extended, the HMI can be exchanged and the process controller replaced. Depending on the software used the process picture will look differently. Below is a view of Figurate™ powered by DeltaV™ automation software." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: DCS_screen,
              alt: "Distributed control system",
              customWidth: "100%",
            },
          ]}
        />
      </div>
    </>
  );
};
