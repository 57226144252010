import React, { useState } from "react";
import { Title, Text, Spacer, List, TextInline } from "../dynamic.components";
import { useStore } from "src/store";
import { selectLed } from "src/services/light-animations";

export const SystemStatus: React.FC = () => {
  const {
    activeSubLink: { title, extras },
    animationIsPlaying,
  } = useStore();
  const initialState = {
    off: false,
    white: false,
    green: false,
    blink_green: false,
    yellow: false,
    red: false,
  };
  const [state, setState] = useState<any>({
    ...initialState,
  });

  const handleLight = (light: string) => {
    setState({ ...initialState, [light]: true });
    const frame = extras?.staticFrameWithHotspot?.find(
      (hotspot) => hotspot.name === light
    )?.frame;
    frame && selectLed(undefined, frame);
    if (light === "blink_green") {
      extras?.animationSequences &&
        !animationIsPlaying &&
        selectLed(extras?.animationSequences[0], undefined);
    }
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="The installation switch is changed to either" />
        <List
          orderList={true}
          listArray={[
            "flow kit install which opens up and locks the valves for safety precaution prior to operator interaction or",
            "column install for opening up the pathway to the air trap for air removal from column connection.",
          ]}
        />
        <Spacer />
        <Text text="It is easy to see the system status by looking at the light." />
        <Spacer />
        <div className="c-sidebar__led-container">
          <div className="led off">
            <button
              disabled={animationIsPlaying}
              className={`led-button ${state.off ? "off--active" : ""}`}
              onClick={() => handleLight("off")}
            >
              <TextInline text="Light off" />
            </button>
            <TextInline text=" => communication not established" />
          </div>
          <Spacer />
          <div className="led white">
            <button
              disabled={animationIsPlaying}
              onClick={() => handleLight("white")}
              className={`led-button ${state.white ? "white--active" : ""}`}
            >
              <TextInline text="White" />
            </button>
            <TextInline text=" => ready (communication established / end)" />
          </div>
          <Spacer />
          <div className="led green">
            <button
              disabled={animationIsPlaying}
              onClick={() => handleLight("green")}
              className={`led-button ${state.green ? "green--active" : ""}`}
            >
              <TextInline text="Green" />
            </button>
            <TextInline text=" => run (in use)" />
          </div>
          <Spacer />
          <div className="led blink-green">
            <button
              disabled={animationIsPlaying}
              onClick={() => handleLight("blink_green")}
              className={`led-button ${
                state.blink_green && animationIsPlaying
                  ? "blink-green--active"
                  : ""
              }`}
            >
              <TextInline text="Blinking green" />
            </button>
            <TextInline text=" => pause" />
          </div>
          <Spacer />
          <div className="led yellow">
            <button
              disabled={animationIsPlaying}
              onClick={() => handleLight("yellow")}
              className={`led-button ${state.yellow ? "yellow--active" : ""}`}
            >
              <TextInline text="Yellow" />
            </button>
            <TextInline text=" => warning" />
          </div>
          <Spacer />
          <div className="led red">
            <button
              disabled={animationIsPlaying}
              onClick={() => handleLight("red")}
              className={`led-button ${state.red ? "red--active" : ""}`}
            >
              <TextInline text="Red" />
            </button>
            <TextInline text=" => alarm / emergency stop" />
          </div>
        </div>

        <Spacer x={2} />
        <Text text="The emergency button is easily accessed which shuts down the system operation immediately when pushed. " />
      </div>
    </>
  );
};
