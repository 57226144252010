import React, { FC } from "react";
import { SubLinkId } from "src/data";
import { useStore } from "src/store";
import { Footprint } from "./contents/overview/Footprint";
import { SystemStatus } from "./contents/overview/System_status";
import { ChangeOverProcess } from "./contents/overview/Changeover_process";
import { ValidationRegulatorySupport } from "./contents/overview/Validation_regulatory_support";
import { Scalabilty } from "./contents/flexibility/Scalabilty";
import { InputOutputConnectivity } from "./contents/flexibility/Input_output_connectivity";
import { XcellerexXduoMixers } from "./contents/flexibility/Xcellerex_xduo_mixers";
import { DryWetTestFlowKits } from "./contents/system-operarions/Dry_wet_test_flow_kits";
import { ColumnRange } from "./contents/system-operarions/Column-range";
import { ColumnSetup } from "./contents/system-operarions/Column-setup";
import { IntegratedHmiUnicorn } from "./contents/automation/Integrated_hmi_unicorn";
import { DcsOptions } from "./contents/automation/Dcs_options";
import { OpcOptions } from "./contents/automation/Opc_options";
import { PumpInlets } from "./contents/process-control/Pump_inlets";
import { Sensors } from "./contents/process-control/Sensors";
import { InlineAirSensorTrap } from "./contents/process-control/Inline_air_sensor_trap";
import { ColumnOutlets } from "./contents/process-control/Column_outlets";

const Content: FC = (): JSX.Element => {
  const { activeSubLink } = useStore();
  switch (activeSubLink.id) {
    case SubLinkId.footprint:
      return <Footprint />;
    case SubLinkId.system_status:
      return <SystemStatus />;
    case SubLinkId.changeover_process:
      return <ChangeOverProcess />;
    case SubLinkId.validation_regulatory_support:
      return <ValidationRegulatorySupport />;
    case SubLinkId.scalabilty:
      return <Scalabilty />;
    case SubLinkId.input_output_connectivity:
      return <InputOutputConnectivity />;
    case SubLinkId.xcellerex_xduo_mixers:
      return <XcellerexXduoMixers />;
    case SubLinkId.dry_wet_test_flow_kits:
      return <DryWetTestFlowKits />;
    case SubLinkId.column_range:
      return <ColumnRange />;
    case SubLinkId.column_setup:
      return <ColumnSetup />;
    case SubLinkId.integrated_hmi_unicorn:
      return <IntegratedHmiUnicorn />;
    case SubLinkId.dcs_options:
      return <DcsOptions />;
    case SubLinkId.opc_options:
      return <OpcOptions />;
    case SubLinkId.pump_inlets:
      return <PumpInlets />;
    case SubLinkId.sensors:
      return <Sensors />;
    case SubLinkId.inline_air_sensor_trap:
      return <InlineAirSensorTrap />;
    case SubLinkId.column_outlets:
      return <ColumnOutlets />;
    default:
      return <></>;
  }
};

const SidebarContent: FC = (): JSX.Element => {
  return (
    <div className="c-sidebar__wrapper">
      <Content />
    </div>
  );
};

export default SidebarContent;
