import React from "react";
import { useStore } from "src/store";
import { Title, Spacer, TextInline } from "../dynamic.components";
import { setAnimationFrame } from "src/services/animations";

export const Sensors: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { title, staticFrameWithHotspot },
  } = useStore();

  const handleSelectHotspot = (h: string) => {
    const selectedHotspot = staticFrameWithHotspot?.findIndex(
      (hot) => hot.name === h
    ) as number;
    staticFrameWithHotspot &&
      setAnimationFrame(staticFrameWithHotspot[selectedHotspot]);
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <button
          onClick={() => handleSelectHotspot("conductivity")}
          className="button button--link button--xlarge button__content"
        >
          Conductivity
        </button>
        <TextInline text=" : monitors and/or controls gradient creation pre-column. Post-column monitors elution conductivity." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("pressure")}
          className="button button--link button--xlarge button__content"
        >
          Pressure
        </button>
        <TextInline text=" : for preventing over pressure pre- column and by the outlets." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("flow")}
          className="button button--link button--xlarge button__content"
        >
          Flow
        </button>
        <TextInline text=" : monitors the flow rate through the column." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("uv")}
          className="button button--link button--xlarge button__content"
        >
          UV
        </button>
        <TextInline text=" : The system includes a three-wavelength UV monitor which can capture the absorbance signal from up to three wavelengths between 206 and 700 nm simultaneously. The system can also calculate the UV absorbance ratio which can be displayed as a curve in the chromatogram during active system run and in the result file." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("pH_temperature")}
          className="button button--link button--xlarge button__content"
        >
          pH / Temperature
        </button>
        <TextInline text=" : The integrated pH electrode in the flow path does not require calibration and allows for closed operation when a pH electrode is required in the operation. Temperature is measured in the pH electrode for temperature compensation." />
      </div>
    </>
  );
};
