import React from "react";
import {
  Title,
  Text,
  Spacer,
  CustomLink,
  TextImage,
} from "../dynamic.components";
import { useStore } from "src/store";
import akta_ready_450 from "src/assets/images/flexibility/B_01_r-450.png";
import akta_ready_uv from "src/assets/images/flexibility/B_02_r-iso.png";
import akta_ready_iso from "src/assets/images/flexibility/B_03_r-UV.png";
import akta_extended from "src/assets/images/flexibility/B_04_r-ext.png";
import akta_xl from "src/assets/images/flexibility/B_05_r-XL.png";

export const Scalabilty: React.FC = () => {
  const { title } = useStore().activeSubLink;

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="The ÄKTA ready™ extended is part of the ÄKTA ready family which covers the processing from the early stages at small volumes with flow down to 5 ml/min up to large scale manufacturing at 3500L/h" />
        <Spacer />

        <TextImage
          imageSrc={akta_ready_450}
          imageAlt="ÄKTA ready 450"
          title="ÄKTA ready 450"
          text="Compact system for small scale manufacturing in which very small quantities can be processed at flow rates as low as 5ml/min. The system has comprehensive functionality including a 3-wavelength UV sensor (3 simultaneously monitored wavelengths in the range 206-700nm)  that make it suitable for processing of viral vectors and nucleic acids. There is a range of flow kits available including fully closed options to protect sensitive material from contamination."
        />
        <Spacer x={2} />
        <TextImage
          imageSrc={akta_ready_iso}
          imageAlt="ÄKTA ready isocratic"
          title="ÄKTA ready (isocratic or gradient)"
          text="Robust single-use chromatography system with isocratic or gradient capability and essential functionality and sensor capability for process scale-up and manufacturing. With the UV monitoring at 280 nm, typical applications include monoclonal antibodies (mAbs), mAb variants (e.g. bispecifics, antibody Fabs, and Fc-fusion proteins)  and recombinant protein purifications."
        />
        <Spacer x={2} />
        <TextImage
          imageSrc={akta_ready_uv}
          imageAlt="ÄKTA ready variable UV"
          title="ÄKTA ready variable UV"
          text="Same functionality as ÄKTA ready™ system, but includes a three UV-wavelength monitor and single-use pH sensor that support closed processing. Typical applications include separation of full and empty adeno-associated virus (AAV) capsids, and purification of nucleic acids such as plasmids and mRNA."
        />
        <Spacer x={2} />
        <TextImage
          imageSrc={akta_extended}
          imageAlt="ÄKTA ready extended"
          title="ÄKTA ready extended"
          text="This system has increased capabilities compared to ÄKTA ready™ variable UV system that provides more flexibility in production. Some of the added capabilities include more inlets, column flow path options, in-line air sensors, monitoring and control of Xcellerex™ XDUO mixers, and automated air trap. The system also includes an integrated HMI."
        />
        <Spacer x={2} />
        <TextImage
          imageSrc={akta_xl}
          imageAlt="ÄKTA ready XL"
          title="ÄKTA ready XL"
          text="Large scale manufacturing system that is compact yet has capability to process up to 3500 L/h. The system has comprehensive functionality that provide flexibility for various types of processing. ÄKTA ready™ XL system is typically used for late clinical or commercial scale operation."
          arrayList={[
            "Verified methods and intuitive workflows",
            "UNICORN™ control software enables scalability for ÄKTA™ systems",
            "Process equipment with sanitary design suitable for use in regulated environments",
            "Distributed control system option for process equipment",
          ]}
        />
        <Spacer x={2} />
        <CustomLink
          href="https://www.cytivalifesciences.com/solutions/bioprocessing/products-and-solutions/single-use-solutions/chromatography-systems?extcmp=cy23007-global-bp-pi-aktaready-3d-ot-qrc"
          text="Learn more"
          inline={true}
        />
      </div>
    </>
  );
};
