import React from "react";
import { resetCameraAnnotation } from "src/constants";
import { resetAnimationFrame } from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "../../store";
import { SidebarVisibility, SubLink } from "../../types/index.d";
import SidebarContent from "./Sidebar.content.component";
import {
  playCustomAnimationWithoutRotation,
  playOneAnimationWithoutRotation,
} from "src/services/animations-without-rotation";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  const {
    activeSubLink,
    sidebarStatus,
    setSidebarStatus,
    animationIsPlaying,
    activeNavLink,
    setReplayAnimationClicked,
    setActiveSubLink,
    activeModel,
  } = useStore();
  const closeSidebar = () => {
    resetAnimationFrame();

    activeNavLink.annotations
      ? playHotspot(activeNavLink.annotations[0])
      : playHotspot(resetCameraAnnotation);

    setSidebarStatus(SidebarVisibility.notShow);

    setTimeout(() => {
      setActiveSubLink({} as SubLink);
    }, 350);
  };

  const replayAnimation = () => {
    setReplayAnimationClicked(true);
    if (activeSubLink.id === "5-4") {
      let annotation = "";
      let animationSeq = [0, 0];

      if (activeSubLink.extras?.activeAnimation === 0) {
        annotation =
          (activeSubLink.annotations && activeSubLink.annotations[0]) || "";
        animationSeq =
          (activeSubLink.animationSequences &&
            activeSubLink.animationSequences[0]) ||
          [];
      }

      if (activeSubLink.extras?.activeAnimation === 1) {
        annotation =
          (activeSubLink.extras?.annotations &&
            activeSubLink.extras?.annotations[0]) ||
          "";
        animationSeq =
          (activeSubLink.extras?.animationSequences &&
            activeSubLink.extras?.animationSequences[0]) ||
          [];
      }
      playOneAnimationWithoutRotation(animationSeq, true, true);
    } else playCustomAnimationWithoutRotation();
  };

  return (
    <div
      className={`c-sidebar ${
        sidebarStatus === SidebarVisibility.show
          ? "show-sidebar-motion"
          : sidebarStatus === SidebarVisibility.notShow && "hide-sidebar-motion"
      }`}
    >
      <>
        <button
          className={`button button--secondary button--xlarge button--thick-border ${
            animationIsPlaying && "button--disabled"
          }`}
          onClick={closeSidebar}
          disabled={animationIsPlaying}
        >
          Return to 3D overview
        </button>
        <SidebarContent />
        {activeSubLink.animations && activeSubLink.animations.length && (
          <button
            className={`button button--secondary button--xlarge button--thick-border ${
              animationIsPlaying ? "button--disabled" : ""
            }`}
            onClick={replayAnimation}
            disabled={animationIsPlaying}
          >
            Replay Animation
          </button>
        )}
      </>
    </div>
  );
};

export default Sidebar;
