import React from "react";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import { useStore } from "src/store";
import D_03_illustration from "src/assets/images/automation/D_03_illustration.png";

export const OpcOptions: React.FC = () => {
  const {
    activeSubLink: { title },
  } = useStore();

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="The ÄKTA ready™ extended system and UNICORN™ software allow standalone operations or integration into plant wide control system. UNICORN software can be integrated with other control systems and manufacturing environments using Open platform Communications-Unified Architecture (OPC-UA). OPC-UA communication is possible with UNICORN versions 7.8 and later." />
        <Spacer x={2} />
        <Image
          images={[
            {
              src: D_03_illustration,
              alt: "Illustration",
              customWidth: "100%",
            },
          ]}
        />
      </div>
    </>
  );
};
