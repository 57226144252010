import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { produce } from "immer";
import {
  Animation,
  Annotation,
  CustomLink,
  CytivaState,
  LampName,
  SubLink,
  Overlays,
  SidebarVisibility,
  TreeDModel,
} from "../types/index.d";
import { links } from "../data";

export const useStore = create<CytivaState, any>(
  devtools((set) => ({
    links: links,
    apiref: "",
    hasName: true,
    setApiref: (api: any) =>
      set(
        produce((state: CytivaState) => {
          state.apiref = api;
        })
      ),
    viewIsReady: false,
    setViewIsReady: (isReady: boolean) =>
      set(
        produce((state: CytivaState) => {
          state.viewIsReady = isReady;
        })
      ),
    sidebarStatus: SidebarVisibility.notSet,
    animations: [],
    setAnimations: (animations: Animation[]) =>
      set(
        produce((state: CytivaState) => {
          state.animations = animations;
        })
      ),
    setActiveAnimation: (index: number) =>
      set(
        produce((state: CytivaState) => {
          if (state.activeSubLink.extras) {
            state.activeSubLink.extras.activeAnimation = index;
          }
        })
      ),
    annotations: [],
    setAnnotations: (annotations: Annotation[]) =>
      set(
        produce((state: CytivaState) => {
          state.annotations = annotations;
        })
      ),
    activeNavLink: {} as CustomLink,
    setActiveNavLink: (navLink: CustomLink) =>
      set(
        produce((state: CytivaState) => {
          state.activeNavLink = navLink;
        })
      ),
    activeSubLink: links[0].subLinks[0],
    setActiveSubLink: (subLink: SubLink) =>
      set(
        produce((state: CytivaState) => {
          state.activeSubLink = subLink;
        })
      ),
    setSidebarStatus: (status: SidebarVisibility) =>
      set(
        produce((state: CytivaState) => {
          state.sidebarStatus = status;
        })
      ),
    materials: [],
    stackLights: {
      stacklight_RED_EZ: false,
      stacklight_ORANGE_EZ: false,
      stacklight_GREEN_EZ: false,
      stacklight_BLUE_EZ: false,
      stacklight_WHITE_EZ: false,
    },
    toggleLamp: (lampName: LampName) =>
      set(
        produce((state: CytivaState) => {
          state.stackLights[lampName] = !state.stackLights[lampName];
        })
      ),
    hasDropdown: true,
    dropdown: false,
    setDropdown: () =>
      set(
        produce((state: CytivaState) => {
          state.dropdown = !state.dropdown;
        })
      ),
    overlays: {
      trademark: false,
      help: false,
      information: false,
    },
    toggleOverlay: (overlay: Overlays) =>
      set(
        produce((state: CytivaState) => {
          state.overlays[overlay] = !state.overlays[overlay];
        })
      ),
    closeOverlay: () =>
      set(
        produce((state: CytivaState) => {
          if (state.overlays.help) state.overlays.help = false;
          if (state.overlays.trademark) state.overlays.trademark = false;
          if (state.overlays.information) state.overlays.information = false;
        })
      ),
    animationIsPlaying: false,
    animationStopped: true,
    setAnimationStopped: (status: boolean) =>
      set(
        produce((state: CytivaState) => {
          state.animationStopped = status;
        })
      ),
    footprintVisible: false,
    setFootprintVisible: (footprintShow: boolean) =>
      set(
        produce((state: CytivaState) => {
          state.footprintVisible = footprintShow;
        })
      ),
    activeModel: TreeDModel.eight_K_Plus,
    changeModel: (selectModel: TreeDModel) =>
      set(
        produce((state: CytivaState) => {
          state.activeModel = selectModel;
        })
      ),
    replayAnimationclicked: false,
    setReplayAnimationClicked: (b: boolean) =>
      set(
        produce((state: CytivaState) => {
          state.replayAnimationclicked = b;
        })
      ),
  }))
);
