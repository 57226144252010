import React, { FC } from "react";

export const Text: FC<{ text: string }> = ({ text }) => {
  return <p className="c-sidebar__text">{text}</p>;
};

export const TextSmall: FC<{ text: string; inline?: boolean }> = ({
  text,
  inline = false,
}) => {
  return (
    <p className={`c-sidebar__text-small ${inline ? "text-inline" : ""}`}>
      {text}
    </p>
  );
};

export const TextInline: FC<{
  text: string;
  weight?: string;
  fontStyle?: string;
  customClass?: string;
}> = ({ text, weight = "", fontStyle = "", customClass = "" }) => {
  return (
    <p
      style={{ fontWeight: weight, fontStyle: fontStyle }}
      className={`c-sidebar__text text-inline ${customClass}`}
    >
      {text}
    </p>
  );
};

export const Title: FC<{ title: string }> = ({ title }) => {
  return <h2 className="c-sidebar__title">{title}</h2>;
};

export const Subtitle: FC<{ subtitle: string; color?: "green" | "black" }> = ({
  subtitle,
  color = "black",
}) => {
  return <h2 className={`c-sidebar__subtitle color--${color}`}>{subtitle}</h2>;
};

export const Image: FC<{
  images: {
    src: any;
    alt?: string;
    customWidth?: string;
    customHeight?: string;
    title?: string;
  }[];
}> = ({ images }) => {
  return (
    <div className="c-sidebar__image-container">
      {images.map((image, index) => (
        <div
          key={index}
          className="c-sidebar__image-item"
          style={{
            width: image.customWidth ? `${image.customWidth}` : "",
            height: image.customHeight ? `${image.customHeight}` : "",
          }}
        >
          {image.title && (
            <p className="c-sidebar__image-title">{image.title}</p>
          )}
          <img
            className="c-sidebar__image-img"
            src={image.src}
            alt={image.alt ? image.alt : image.src}
          />
        </div>
      ))}
    </div>
  );
};

export const List: FC<{
  listArray: string[];
  x?: number;
  orderList?: boolean;
  typeList?: "a" | "i" | "1" | "A" | "I";
  listStyle?: "disc" | "circle" | "square" | "none";
}> = ({
  listArray,
  x = 0,
  orderList = false,
  typeList = "1",
  listStyle = "disc",
}) => {
  const content = listArray.map((list, index) => (
    <li key={index} className="c-sidebar__list-item c-sidebar__text">
      {list}
      <Spacer x={x} />
    </li>
  ));
  return orderList ? (
    <ol type={typeList} className="c-sidebar__list">
      {content}
    </ol>
  ) : (
    <ul style={{ listStyleType: listStyle }} className="c-sidebar__list">
      {content}
    </ul>
  );
};

export const CustomLink: FC<{
  href: string;
  text?: string;
  inline?: boolean;
}> = ({ href, text = "", inline = false }) => {
  return (
    <a
      className="button--link c-sidebar__link"
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >
      <p style={inline ? { display: "inline" } : {}}>{text ? text : href}</p>
    </a>
  );
};

export const TextImage = ({
  text,
  imageSrc,
  imageAlt = "",
  title = "",
  align = "left",
  arrayList,
}: {
  text: string;
  imageSrc: string;
  imageAlt?: string;
  title?: string;
  arrayList?: string[];
  align?: "left" | "right";
}) => {
  return (
    <div>
      <Subtitle subtitle={title} color="green" />
      <Spacer />
      <div className={`text-image text-image__align--${align}`}>
        <img src={imageSrc} alt={imageAlt} />
        <Spacer x={1.5} />
        <div>
          <Text text={text} />
          <Spacer />
          {arrayList ? <List listArray={arrayList} /> : ""}
        </div>
      </div>
    </div>
  );
};

export const Video: FC<{ src: string }> = ({ src }) => {
  return (
    <>
      <video controls>
        <source src={src} type="video/mp4" />
      </video>
    </>
  );
};

export const IframeVideo: FC<{ src: string }> = ({ src }) => {
  return (
    <iframe
      className="c-sidebar__youtube-video"
      src={src}
      scrolling="no"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export const Spacer: FC<{ x?: number }> = ({ x = 1 }) => (
  <span style={{ padding: `${x * 5}px`, display: "block" }}></span>
);

export const Svg: FC<{ icon: any; id: string }> = ({ icon, id }) => (
  <svg className="c-sidebar__svg">
    <use xlinkHref={`${icon}#${id}`} />
  </svg>
);
