import React from "react";
import { useStore } from "src/store";
import { Spacer } from "../sidebar/contents/dynamic.components";

const TradeMark = ({}) => {
  const { closeOverlay } = useStore();
  return (
    <>
      <div
        className="c-cytiva__trademark-section"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="c-cytiva__trademark-top">
          <img
            className="c-cytiva__trademark-top__logo"
            src="favicon.ico"
            width="40"
            alt="Cytiva logo"
          />
          <button
            className="c-cytiva__trademark-top__close"
            onClick={closeOverlay}
          >
            X
          </button>
        </div>
        <p className="c-cytiva__trademark-text">
          Cytiva and the Drop logo are trademarks of Life Sciences IP Holdings
          Corp. or an affiliate doing business as Cytiva.
          <Spacer />
          ÄKTA, ÄKTA ready, Capto, Figurate, MabSelect, ReadyToProcess,
          Sepharose, UNICORN, and Xcellerex are trademarks of Global Life
          Sciences Solutions USA LLC or an affiliate doing business as Cytiva.
          <Spacer />
          The use of ÄKTA ready High Flow Kit and Low Flow Kit may require a
          license under the following US patents and equivalents patents in
          other countries: US Pat No. 6,712,963 and 7,052,603 (assignee: SciLog,
          Inc.). A free, non-transferable license limited to using these
          products under above mentioned patents accompanies the purchase of the
          product from any Cytiva company and its licensed distributors.
          <Spacer />
          DeltaV is a trademark of Emerson Electric Co. Any other third-party
          trademarks are the property of their respective owners.
          <Spacer />
          Any use of UNICORN software is subject to Cytiva Standard Software
          End-User License Agreement for Life Sciences Software Products. A copy
          of this Standard Software End-User License Agreement is available on
          request.
          <Spacer />
          © 2023 Cytiva.
          <Spacer />
          For local office contact information, visit:{" "}
          <a
            className="c-cytiva__trademark-text"
            style={{ textDecoration: "none" }}
            href="https://cytiva.com/contact"
            target="_blank"
          >
            cytiva.com/contact
          </a>
        </p>
      </div>
    </>
  );
};

export default TradeMark;
