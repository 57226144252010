import React, { useEffect } from "react";
import {
  playCustomAnimation,
  setAnimationFrame,
} from "src/services/animations";
import { useStore } from "src/store";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { Title, Text, Spacer } from "../dynamic.components";

export const DryWetTestFlowKits: React.FC = () => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { title, staticFrameWithHotspot },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-1")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("3-1")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  const handleSelectHotspot = (h: string) => {
    const selectedHotspot = staticFrameWithHotspot?.findIndex(
      (hot) => hot.name === h
    ) as number;
    staticFrameWithHotspot &&
      setAnimationFrame(staticFrameWithHotspot[selectedHotspot]);
  };

  const KitOnly = () => {
    return (
      <>
        The{" "}
        <button
          onClick={() => handleSelectHotspot("kit_only")}
          className="button button--link button--xlarge button__content"
        >
          dry test
        </button>{" "}
        will test that all sensors are connected properly.
      </>
    );
  };
  const KitColumn = () => {
    return (
      <>
        The{" "}
        <button
          onClick={() => handleSelectHotspot("kit_column")}
          className="button button--link button--xlarge button__content"
        >
          wet test
        </button>{" "}
        requires a test solution to be prepared and pumped through the flow kit.
        This will verify the flow kit size, pump performance and that sensors
        provide relevant reading.
      </>
    );
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="When the flow kit has been installed there are both a dry test and a wet test that can be carried out to ensure everything is set up correctly prior to start processing." />
        <Spacer />
        <ul style={{ listStyleType: "disc" }} className="c-sidebar__list">
          {staticFrameWithHotspot?.map((hotspot) => (
            <li
              key={hotspot.name}
              className="c-sidebar__list-item c-sidebar__text"
            >
              {hotspot.name === "kit_only" ? <KitOnly /> : <KitColumn />}
              <Spacer x={0.5} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
