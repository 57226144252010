import React from "react";
import { Title, Text, Spacer, Image } from "../dynamic.components";
import { useStore } from "src/store";
import footprint from "src/assets/images/overview/B_04_r-ext.png";

export const Footprint: React.FC = () => {
  const { title } = useStore().activeSubLink;

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="ÄKTA ready™ extended has comprehensive system functionalities in a compact footprint. This enables efficient use of clean room floor space." />
        <Spacer />
        <Text text="Two flow path sizes can be used on the same hardware which reduces the need for additional hardware units in the process area." />

        <Spacer x={3} />
        <Image
          images={[
            {
              src: footprint,
              alt: "Footprint",
              customWidth: "100%",
            },
          ]}
        />
      </div>
    </>
  );
};
