import React, { useEffect } from "react";
import { useStore } from "src/store";
import { SidebarVisibility, TreeDModel } from "src/types/index.d";
import { Title, Spacer, List, Text, Image } from "../dynamic.components";
import capacity_sample_hotel from "src/assets/images/3_4_Increased_capacity_Sample_hotel.jpg";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import { playAnimationByModel } from "src/services/animations";

export const ColumnSetup: React.FC = (): JSX.Element => {
  const {
    activeSubLink: { title },
    animationStopped,
    setAnimationStopped,
    sidebarStatus,
    changeModel,
    activeModel,
    animationIsPlaying,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("3-3")) {
      playAnimationByModel(activeModel);
    }
    if (componentAnimationReadyToStop("3-3")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="Place the column trolley close to the system and connect the column to the flow kit." />
        <Spacer />
        <Text text="After attaching a ReadyToProcess™ column, turn the system switch to “Column Install” mode. The valves will open up and air near the column inlet connection can travel up to the air trap. When air removal in the pre-column flow path is complete, equilibration buffer can be run through the column to remove any remaining post-column air." />
      </div>
    </>
  );
};
