import React, { useEffect } from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer, TextInline } from "../dynamic.components";
import {
  componentAnimationReadyToPlay,
  componentAnimationReadyToStop,
} from "src/utils/helper";
import {
  playCustomAnimation,
  setAnimationFrame,
} from "src/services/animations";

export const PumpInlets: React.FC = (): JSX.Element => {
  const {
    sidebarStatus,
    animationStopped,
    setAnimationStopped,
    activeSubLink: { title, extras },
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("5-1")) {
      playCustomAnimation();
    }
    if (componentAnimationReadyToStop("5-1")) {
      setAnimationStopped(true);
    }
  }, [sidebarStatus, animationStopped]);

  const handleSelectHotspot = () => {
    extras?.staticFrameWithHotspot &&
      setAnimationFrame(extras.staticFrameWithHotspot[0]);
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="The system includes six inlets per pump. This provide flexibility to connect buffers to either pump inlet manifold at your choice." />
        <Spacer />
        <TextInline text="The two " />
        <button
          onClick={handleSelectHotspot}
          className="button button--link button--xlarge button__content"
        >
          pumps
        </button>{" "}
        <TextInline text="can either be run separately or in parallel. Both step and gradient elutions are possible. The gradients can be created and controlled by either conductivity feed-back or by pump speed." />
        <Spacer />
        <Text text="The flow generated by the pumps can be controlled either by flow control or pressure control depending on the desired mode of operation." />
      </div>
    </>
  );
};
