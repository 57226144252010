import React, { useEffect, useState } from "react";
import { useStore } from "src/store";
import { Title, Text, Spacer } from "../dynamic.components";
import { setAnimationFrame } from "src/services/animations";
import { componentAnimationReadyToPlay } from "src/utils/helper";

export const InlineAirSensorTrap: React.FC = (): JSX.Element => {
  const initialState = {
    air_trap: false,
    air_sensor: false,
    clamp_on: false,
  };
  const [state, setState] = useState({ ...initialState });

  const {
    activeSubLink: { title, staticFrameWithHotspot },
    sidebarStatus,
  } = useStore();

  useEffect(() => {
    if (componentAnimationReadyToPlay("5-3")) {
      staticFrameWithHotspot && setAnimationFrame(staticFrameWithHotspot[0]);
      setState({ ...initialState, air_trap: true });
    }
  }, [sidebarStatus]);

  const handleSelectHotspot = (h: string) => {
    setState({ ...initialState, [h]: true });

    const selectedHotspot = staticFrameWithHotspot?.findIndex(
      (hot) => hot.name === h
    ) as number;
    staticFrameWithHotspot &&
      setAnimationFrame(staticFrameWithHotspot[selectedHotspot]);
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="Up to two clamp-on air sensors can be placed on the inlet tubing or up to two in-line air sensors that can be connected to the flow kit at the position of your choice." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("clamp_on")}
          className={`button button--secondary button--large button--fixwidth ${
            state.clamp_on ? "button--hover" : ""
          }`}
        >
          Clamp-on
        </button>
        <Spacer x={2} />
        <Text text="The air trap allows efficient removal of air that has entered the liquid flow path. The air trap is automated and controlled by the level sensors attached to the outside of the air trap bell." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("air_trap")}
          className={`button button--secondary button--large button--fixwidth ${
            state.air_trap ? "button--hover" : ""
          }`}
        >
          Air trap
        </button>
        <Spacer x={2} />
        <Text text="There is also an in-line air sensor between the air trap and the column to protect air from entering the column if the air trap is bypassed." />
        <Spacer />
        <button
          onClick={() => handleSelectHotspot("air_sensor")}
          className={`button button--secondary button--large button--fixwidth ${
            state.air_sensor ? "button--hover" : ""
          }`}
        >
          Pre-column air sensor
        </button>
      </div>
    </>
  );
};
