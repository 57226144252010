import React, { useEffect, useState } from "react";
import { Title, Text, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import { setAnimationFrame } from "src/services/animations";

export const InputOutputConnectivity: React.FC = () => {
  const {
    activeSubLink: { title, extras },
  } = useStore();

  const initialState = {
    air_sensor_connections: false,
    other_connections: false,
    profibus_connection: false,
  };
  const [state, setState] = useState({
    ...initialState,
  });

  useEffect(() => {
    setState({ ...initialState, air_sensor_connections: true });

    return () => {
      setState({ ...initialState });
    };
  }, []);

  const handleSelectAkta = (akta: string) => {
    setState({ ...initialState, [akta]: true });
    const selectedHotspot = extras?.staticFrameWithHotspot?.findIndex(
      (h) => h.name === akta
    ) as number;
    extras?.staticFrameWithHotspot &&
      setAnimationFrame(extras?.staticFrameWithHotspot[selectedHotspot]);
  };

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="ÄKTA ready™ extended can be connected to" />
        <Spacer />
        <List
          x={1}
          listArray={[
            "Up to two clamp-on air sensors on the inlet tubing ",
            "Up to two in-line air sensors that can be connected to the flow kit at your choice, by the inlets or by the column connection",
            "Warnings and alarm signals to facility automation system",
            "Digital input ports for UPS signal and a second port for general purpose use",
            "Ethernet port is for external communication with the system computer, e.g., file transfer and OPC.",
            "Profibus connection for Xcellerex™ XDUO single-use mixers",
          ]}
        />
        <Spacer />
        {extras?.staticFrameWithHotspot &&
          extras.staticFrameWithHotspot.map((hotspot) => (
            <React.Fragment key={hotspot.name}>
              <button
                className={`button button--secondary button--large button--fixwidth ${
                  hotspot.name ===
                  Object.keys(state)[Object.values(state).findIndex((i) => i)]
                    ? "button--hover"
                    : ""
                }`}
                onClick={() => hotspot.name && handleSelectAkta(hotspot.name)}
              >
                {hotspot.annotation === "B2-1"
                  ? "Air sensor connections"
                  : hotspot.annotation === "B2-2"
                  ? "Other connections"
                  : hotspot.annotation === "B2-3"
                  ? "Profibus connection"
                  : ""}
              </button>
              <Spacer />
            </React.Fragment>
          ))}
      </div>
    </>
  );
};
