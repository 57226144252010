import { useStore } from "src/store";

export const selectLed = (animationSequence?: number[], frame?: number) => {
  const { apiref } = useStore.getState();

  if (frame) {
    apiref.pause(() => {
      apiref.seekTo(frame);
    });
  }

  if (animationSequence) {
    let timeoutId;
    const firstSeqTime = animationSequence?.[0];
    const secondSeqTime = animationSequence?.[1];
    const animationDuration =
      firstSeqTime && secondSeqTime && secondSeqTime - firstSeqTime;

    apiref.pause(() => {
      apiref.seekTo(firstSeqTime, () => {
        apiref.play();
        useStore.setState({ animationIsPlaying: true });

        timeoutId = setTimeout(() => {
          apiref.pause();
          useStore.setState({ animationIsPlaying: false });
        }, animationDuration && animationDuration * 1000);
      });
    });
    clearTimeout(timeoutId);
  }
};
