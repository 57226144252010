import React from "react";
import { Title, Text, Spacer, Image, TextSmall } from "../dynamic.components";
import { useStore } from "src/store";
import akta_ready from "src/assets/images/automation/UnicornCapture.png";

export const IntegratedHmiUnicorn: React.FC = () => {
  const {
    activeSubLink: { title },
  } = useStore();

  return (
    <>
      <Title title={title} />
      <div className="c-sidebar__body">
        <Text text="ÄKTA ready™ extended system includes an integrated HMI (human machine interface) to control the system and adjacent Xcellerex™ XDUO single-use mixers." />
        <Spacer />
        <Text text="UNICORN™ software has several features that support chromatography operations such as HETP* and asymmetry testing. For full overview, the HMI screen allows process picture, chromatogram, and event log to be shown at the same time." />
        <Spacer x={2} />
        <TextSmall text="* height equivalent to a theoretical plate" />
        <Spacer x={2} />

        <Image
          images={[
            {
              src: akta_ready,
              alt: "Äkta ready extended system",
              customWidth: "100%",
            },
          ]}
        />
      </div>
    </>
  );
};
